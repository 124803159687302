import './mainpage.css';
import { Helmet } from 'react-helmet';

function Mainpage() {
  return (
    <div>
      <Helmet>
        <title>Gaggao's Site</title>
      </Helmet>
      <h1>Hi! ╰(*°▽°*)╯<br /> Welcome to <span className="PianoNic">Gaggao's</span> Site</h1>
    </div>
  );
}

export default Mainpage;
