import './FourOFour.css';
import { Helmet } from 'react-helmet';

function FourOFour() {
  return (
    <div>
      <Helmet>
        <title>404 - Gaggao's Site</title>
      </Helmet>
      <h1>
        Error <span className="PianoNic">404</span> <br />
        Page Not Found
      </h1>
    </div>
  );
}

export default FourOFour;
