import React from 'react';
import ReactDOM from 'react-dom/client'; // Make sure to import from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Mainpage from './mainpage_component/mainpage';
import FourOFour from './404_component/FourOFour';
import About from './about_component/About';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
// A separate component to handle transitions
const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        timeout={300} // Adjust this value to match your transition duration
        classNames="fade"
      >
        {({ match }) => (
          <div>
            <Routes location={location}>
              <Route path="/" element={<Mainpage />} />
              <Route path="/about" element={<About />} />
              <Route path="/*" element={<FourOFour />} />
            </Routes>
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};


// The root element where the app will be rendered
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendering the application
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <header className="site-header">
          <div className="container">
            <Link to="/">GAGGAO</Link>
            <nav>
              <ul>
                <li><Link to="/about">About Gaggao</Link></li>
              </ul>
            </nav>
          </div>
        </header>
        <div className="hero">
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
        </div>
        <div className="text-container">
          <AnimatedRoutes />
        </div>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
