import React from 'react';
import { Helmet } from 'react-helmet';
import './About.css';

function About() {
  return (
    <div>
      <Helmet>
        <title>About - Gaggao's Site</title>
      </Helmet>
      <h1>
        <span className="PianoNic">About Gaggao</span>
      </h1>
      <br></br>
      <img src='./rick.gif' alt="rick" style={{ width: '70%', borderRadius: '10px' }}/>
      <br></br>
      <br></br>
      <span>
        This Website was made by <a href="https://pianonic.com" style={{ color: '#662e00', textDecoration: 'none' }}>PianoNic</a>
      </span>
    </div>
  );
}

export default About;
